<template>
  <UserProfileMB v-if="layout == 4" />
  <UserProfilePC v-else />
</template>

<script>
import UserProfileMB from "@/pages/mb/UserProfile";
import UserProfilePC from "@/pages/pc/UserProfile";
import { useRoute } from "vue-router";
import { reactive, provide, watch } from "vue";
import UserService from "@/services/user";

export default {
  components: {
    UserProfileMB,
    UserProfilePC,
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      userInfo: {},
      profileId: route.query.profile_id,
    });

    getUserProfile();

    async function getUserProfile() {
      if (state.profileId) {
        const response = await UserService.fetchUser(state.profileId);
        state.userInfo = response.data;
        document.title = ` ${state.userInfo.firstname} | Dosiin Style`;
      }
    }

    watch(
      () => route.query.profile_id,
      () => {
        state.profileId = route.query.profile_id;
        getUserProfile();
      }
    );
    provide("state", state);
  },
};
</script>
