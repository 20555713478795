<template>
    <section class="hashtag-group overflow style-box-wrapper">
        <div class="header-section">
            <h2 class="header-section-left-side section-title">
                {{ $t('style.top_hashtag') }}
            </h2>
        </div>
        <div class="dosiin_hashtag_slider hashtag_slider">
            <HashtagSkeleton v-if="skeleton"/>
            <ul v-else class="hashtag-list hashtag-scroll">
                <li style="display:inline-block" :class="layout == 4 ? 'dosiin_mb-2' :''" v-for="(tag,i) in trendingTags" :key="i" >
                    <router-link :to="{ name: 'tags', query: { tag: tag.tag }}">
                        <div class="hashtag-item dosiin_mr-2">
                            <div class="content">
                                #{{tag.tag}}
                            </div>
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
    import { mapState , mapActions ,mapMutations } from 'vuex';
    import HashtagSkeleton from '@/components/skeletons/HashTag.vue';
    import TagService from '@/services/tag';
    export default {
        components:{
            HashtagSkeleton
        },
        data(){
            return {
                skeleton : true
            }
        },
        computed:{
            ...mapState('PostsStore',['trendingTags'])
        },
        created(){
            this.getTags();
        },
        methods:{
            ...mapActions('PostsStore',['addTags']),
            ...mapMutations('PostsStore',[
                'DELETE_POST_LOADER'
            ]),
            async getTags(){

                const query_string_tag = this.serialize({
                    'object_type':'V', 
                    'sort_by' : 'random' ,
                    'sort_order' : 'desc' ,
                    'limit' : Tygh.layout == 4 ? 30 : 13,
                    'use_caching':true,
                    'group' : 1
                });
                
                const response = await TagService.fetchTags(query_string_tag);
                const tagData  = response.data.tags;
                
                this.addTags({ tagData , type : 'time'});
                this.skeleton = false;
            }
        }
    }
</script>