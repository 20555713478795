<template>
  <main class="dosiin_my-page" :key="state.profileId">
    <section
      class="my-page-section section-header user-logged"
      v-if="state.userInfo.user_id"
    >
      <div class="my-page-infor_user">
        <div class="d-flex align-items-center">
          <div class="my-page-icon inline-bl">
            <div class="my-page-icon-wrapper">
              <img
                class="brand-icon"
                width="90"
                height="90"
                v-lazy="{
                  src: _userAvatar(state.userInfo.avatar),
                  loading: _userDefaultAvatar,
                  error: _userDefaultAvatar,
                }"
              />
            </div>
          </div>
          <div class="infor_user">
            <div class="name-user custom-mb-4" v-text="state.userInfo.firstname"></div>
            <div class="class-user custom-mb-4">
              Thành viên: <span class="class">{{ state.userInfo.loyalty }} </span>
            </div>
            <div class="follow-user">
              <div class="grid grid-40">
                <div class="grid-item d-flex">
                  <h4 v-text="state.userInfo.follow_count"></h4>
                  <span>&nbsp;người theo dõi</span>
                </div>
                <div class="grid-item d-flex">
                  <h4
                    v-text="
                      state.userInfo.following_count ? state.userInfo.following_count : 0
                    "
                  ></h4>
                  <span>&nbsp;đang theo dõi </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a
        class="my-page-user_weblink"
        v-if="state.userInfo.web_link"
        :href="state.userInfo.web_link"
        target="_blank"
        v-text="state.userInfo.web_link"
      ></a>
      <div class="my-page-user_descript" v-if="state.userInfo.descript">
        <p v-text="state.userInfo.descript"></p>
      </div>

      <div class="my-page-banner_btn center">
        <FollowButton
          class="brand_follow-btn primary-button button"
          followable_type="user"
          :followable_id="state.userInfo.user_id"
          :is_follow="state.userInfo.is_follow"
        />
      </div>
    </section>

    <section class="my-page-section my-filter-tabs" id="dosiin_my-filter-tabs">
      <div class="brand-comment_container" id="dosiin_my_comment">
        <ul
          class="btn-group dosiin_filter-tabs mag-tabs"
          role="group"
          data-tabs-container="dosiin_mag-container"
        >
          <li
            class="dosiin_filter-tab mag-tab mag-review active"
            data-target="dosiin_mag-container_style"
          >
            <i class="dsi icon-active dsi-act-fill-imageblank"></i>
            <i class="dsi icon-inactive dsi-fill-imageblank"></i>
            <span>Style</span>
          </li>
        </ul>
        <div class="mag-container" id="dosiin_mag-container">
          <section class="style-post-warpper dosiin_style-post_view">
            <PostsLoader :params="{ sort_by: 'random', user_id: state.profileId }" />
          </section>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { inject } from "vue";
import Attach from "@/components/post/Attach";
import PostsLoader from "@/components/post/PostLoader.vue";

export default {
  components: {
    Attach,
    PostsLoader,
  },
  setup() {
    const state = inject("state");

    return {
      state,
    };
  },
};
</script>
<style>
.primary-button {
  width: 100% !important;
}

.my-page-user_weblink {
  line-height: 150%;
  letter-spacing: 0.02em;
  padding: 12px 18px 5px;
  font-size: 14px;
  color: #0090cd;
  display: block;
  text-decoration: underline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
